//
// Base styles
//

.btn-brand {
  border: 0;
  i {
    display: inline-block;
    width: (($btn-padding-y * 2) + ($font-size-base * $btn-line-height));
    margin: (- $btn-padding-y) (- $btn-padding-x);
    line-height: (($btn-padding-y * 2) + ($font-size-base * $btn-line-height));
    text-align: center;
    background-color: rgba(0, 0, 0, .2);
  }

  i + span {
    margin-left: ($btn-padding-x * 2);
  }

  &.btn-lg {
    i {
      width: (($btn-padding-y-lg * 2) + ($font-size-lg * $btn-line-height-lg));
      margin: (- $btn-padding-y-lg) (- $btn-padding-x-lg);
      line-height: (($btn-padding-y-lg * 2) + ($font-size-lg * $btn-line-height-lg));
    }

    i + span {
      margin-left: ($btn-padding-x-lg * 2);
    }
  }

  &.btn-sm {
    i {
      width: (($btn-padding-y-sm * 2) + ($font-size-sm * $btn-line-height-sm));
      margin: (- $btn-padding-y-sm) (- $btn-padding-x-sm);
      line-height: (($btn-padding-y-sm * 2) + ($font-size-sm * $btn-line-height-sm));
    }

    i + span {
      margin-left: ($btn-padding-x-sm * 2);
    }
  }
}

//
// Variants
//

@each $color, $value in $brands-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}
